import React, { useState } from 'react'
import { CopyToClipboardButton } from '../CopyToClipboardButton'

export const Home = () => {

    return (
        <>
            <div class="popup-content text-black">
                <div>
                    <div className='text-black'>Download link has been expired kindly call on </div>
                    <div> <a href="tel:+918772016540"> 877-201-6540 </a> </div>
                    <div> for installation or download</div>
                </div>
            </div>

            <div>
    </div>

        </>
    )
}