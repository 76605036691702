import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from './component/Home';
import { Software } from './component/Software';
import { HomeNew } from './component/Home/new';
import { Admin } from './component/admin';
import { Deactive } from './component/Software/deactive';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Deactive" element={<Deactive />} />
          <Route path="/dragonadmin" element={<Admin />} />
          <Route path="/new" element={<HomeNew />} />
          <Route path="/software/:id" element={<Software />} />
          <Route path='*'  element={<Home />} />
        </Routes>
      </BrowserRouter>
    )
  }
}


export default App;
