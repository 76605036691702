import React, { useRef } from 'react';

export const CopyToClipboardButton = ({ textToCopy }) => {
  const textAreaRef = useRef(null);

  const handleCopyClick = () => {
    if (textAreaRef.current) {
      textAreaRef.current.select();
      document.execCommand('copy');
      // You can also use the Clipboard API like this:
      // navigator.clipboard.writeText(textToCopy);
    }
  };

  return (
    <div>
      <textarea
        style={{ position: 'absolute', left: '-9999px' }}
        ref={textAreaRef}
        value={textToCopy}
        readOnly
      />
      <button onClick={handleCopyClick}>Copy Url</button>
    </div>
  );
};